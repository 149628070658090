.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  z-index: 1;
  margin-bottom: 1rem;
  box-shadow: #0009 2px 3px 16px 1px;
}

.app-bg {
  position: absolute;
  left: 0%;
  top:0%;
  height: 100%;
  width: 100%;
  filter: blur(11px);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 8s ease-in-out;
  }
}

.App-header {
  /*background: url("../public/zeroz_1.png");
  background-size: contain;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  overflow: hidden;
  border-bottom: 2px solid #444444b5;
}

.App-link {
  color: #61dafb;
  z-index: 1;
}

.albumContainer {
  border: 1px solid #969696;
  border-radius: 2px;
  background-color: #fffffff0;
  padding: 2rem;
  padding-bottom: 0.25rem;
  margin-bottom: 1rem;
  z-index: 1;
  width: 30%;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-shadow: #00000030 5px 8px 6px 0px;
}

.albumSubtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-bottom: 1rem;
  width: 22rem;
}

.albumSubtitle p {
  color:#575757;
  font-size: 1rem;
  margin-bottom: 0.3rem;
}

.albumSubtitle span {
  color:#575757;
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
  font-style: italic;
}

.button {
  height: 3.5rem;
  width: 27rem;
  border-radius: 2px;
  background-color: #ffffff;
  border: 0.5px solid #000000bf;
  z-index: 1;
  text-decoration: none;
  font-size: 1rem;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  color: #000000;
  margin-bottom: 0.8px;
  position: relative;
  box-shadow: #00000030 5px 8px 6px 0px;
}

.buttonPlay {
  height: 1.7rem;
  width: 6rem;
  border-radius: 2px;
  background-color: #ffffff;
  border: 1px solid #303030bf;
  text-decoration: none;
  font-size: 0.8rem;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #1a1a1a;
  letter-spacing: 0.05rem;
  position: absolute;
  right: 13%;
}

.buttonColumn {
  display: flex;
  flex-direction: column;
  width: 6rem;
  border-radius: 2px;
  background-color: #ffffff;
  border: 1px solid #303030bf;
  text-decoration: none;
  font-size: 0.8rem;
  justify-content: center;
  align-items: center;
  color: #1a1a1a;
  letter-spacing: 0.05rem;
}

.footer {
  height: 100px;
  background-color: #000000;
  display: flex;
  justify-content: center;
}

.header-img {
  width: 100%;
}

.icon {
  height: 30px;
  width: 30px;
  margin: 0 15px;
}

.story-bg {
  padding: 2rem 1rem;
  width: 80%;
  z-index: 1;
  background: #000000cf;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: #00000030 5px 8px 6px 0px;
}

.story-bg-img {
  position: absolute;
  left: 0%;
  top: 0%;
  height: 100%;
  width: 100%;
  z-index: 1;
}

@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(2deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 700px) {
  .albumContainer{
    width: 63%;
  }

  .app-bg {
    position: absolute;
    left: -46%;
    top:0%;
    height: 100%;
    width: 200%;
    filter: blur(0px);
  }

  .App-header {
    min-height: 0;
    padding: 4rem 0;
  }

  .button {
    width: 22rem;
  }

  .buttonPlay{
    right: 5%;
  }
}

/*
<link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png">
<link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png">
<link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png">
<link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png">
<link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png">
<link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png">
<link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png">
<link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png">
<link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png">
<link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png">
<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
<link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png">
<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
<link rel="manifest" href="/manifest.json">
<meta name="msapplication-TileColor" content="#ffffff">
<meta name="msapplication-TileImage" content="/ms-icon-144x144.png">
<meta name="theme-color" content="#ffffff">
*/